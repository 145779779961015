import BRASS_CAPITAL_CONTENT from "./brass-capital";
import PRIVACY_POLICY_CONTENT from "./privacy-policy";
import TERMS_OF_SERVICE_CONTENT from "./terms-of-service";
import CARDS_TERMS_AND_CONDITION_CONTENT from "./cards";
import {
  brassCapitalTermsPageUrl,
  cardsTermsAndConditionsPageUrl,
  privacyPolicyPageUrl,
  termsOfServicePageUrl,
} from "data/pageUrl";

export const LINKS = [
  {
    name: "Terms of Service",
    url: termsOfServicePageUrl,
  },
  {
    name: "Privacy Policy",
    url: privacyPolicyPageUrl,
  },
  {
    name: "Brass Capital",
    url: brassCapitalTermsPageUrl,
  },
  {
    name: "Cards",
    url: cardsTermsAndConditionsPageUrl,
  },
];

export const PAGE_CONTENTS = {
  brassCapital: BRASS_CAPITAL_CONTENT,
  privacyPolicy: PRIVACY_POLICY_CONTENT,
  termsOfService: TERMS_OF_SERVICE_CONTENT,
  cards: CARDS_TERMS_AND_CONDITION_CONTENT,
};

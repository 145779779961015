// LOGOS
import Saja from "assets/media/images/customers/saja.png";
import Howdo from "assets/media/images/customers/howdo.png";
import Lafia from "assets/media/images/customers/lafia.png";
import Venia from "assets/media/images/customers/venia.png";
import Zyden from "assets/media/images/customers/zyden.png";
import Parkit from "assets/media/images/customers/parkit.png";
import Sirius from "assets/media/images/customers/sirius.png";
import Paysim from "assets/media/images/customers/paysim.png";
import Gravity from "assets/media/images/customers/gravity.png";
import Wirepay from "assets/media/images/customers/wirepay.png";
import Voyance from "assets/media/images/customers/voyance.png";
import Scorefam from "assets/media/images/customers/scorefam.png";
import Beaumont from "assets/media/images/customers/beaumont.png";
import EdenLife from "assets/media/images/customers/edenlife.png";
import Payrouse from "assets/media/images/customers/payrouse.png";
import Myyinvest from "assets/media/images/customers/myyinvest.png";
import Tellerpay from "assets/media/images/customers/tellerpay.png";
import Homerunner from "assets/media/images/customers/homerunner.png";

// PROFILES
import Ebot from "assets/media/images/customers/profiles/ebot.png";
import Mary from "assets/media/images/customers/profiles/mary.png";
import Seyi from "assets/media/images/customers/profiles/seyi.png";
import Simi from "assets/media/images/customers/profiles/simi.png";
import Bashir from "assets/media/images/customers/profiles/bashir.png";
import Gerald from "assets/media/images/customers/profiles/gerald.png";
import Mojola from "assets/media/images/customers/profiles/mojola.png";
import Oliver from "assets/media/images/customers/profiles/oliver.png";
import Prince from "assets/media/images/customers/profiles/prince.png";
import Gbenga from "assets/media/images/customers/profiles/gbenga.png";
import Adebisi from "assets/media/images/customers/profiles/adebisi.png";
import Bolanle from "assets/media/images/customers/profiles/bolanle.png";
import Johnson from "assets/media/images/customers/profiles/johnson.png";
import Miracle from "assets/media/images/customers/profiles/miracle.png";
import Omolara from "assets/media/images/customers/profiles/omolara.png";
import Eyimisan from "assets/media/images/customers/profiles/eyimisan.png";
import Damilola from "assets/media/images/customers/profiles/damilola.png";
import Oluwatobi from "assets/media/images/customers/profiles/oluwatobi.png";
import Aderinsola from "assets/media/images/customers/profiles/aderinsola.png";

export const STORIES = [
  {
    logo: "Homerunner",
    story:
      "From the welcome email to the call I received. I already signed up on other platforms but I saw a reflection of my own company. I am trying to solve a problem in my own space and Brass is trying to solve my own problem.",
    name: "Eyimisan Abusomwan",
    title: "Founder, Homerunner",
    image: Homerunner,
    profile: Eyimisan,
  },
  {
    logo: "Payrouse",
    story:
      "Brass has all the features a modern day business needs. Segmented payments, multisig approvals and a lot more. Their API makes sending payments to users automatically so reliably smooth.",
    name: "Bashir Aminu",
    title: "Co-founder, Payourse Inc",
    image: Payrouse,
    profile: Bashir,
  },
  {
    logo: "Voyance",
    story:
      "Brass has provided us with a strategic backup solutions beyond our expectations, responsiveness and support from their team is second to none.",
    name: "Ebot tabi",
    title: "Founder and CEO, Voyance",
    image: Voyance,
    profile: Ebot,
  },
  {
    logo: "Wirepay",
    story:
      "It’s one of the easiest things we’ve done since we started this company, on-boarding to Brass is one of the easiest things. Very good and fast, everything was done under a couple of hours",
    name: "Miracle Anyanwu",
    title: "Wirepay",
    image: Wirepay,
    profile: Miracle,
  },
  {
    logo: "Myy Invest",
    story:
      "There is an ease that Brass gives. From when you think about a business to how you move your money, Brass is on that journey with you and you don't worry too much",
    name: "Adebisi Borokinni",
    title: "Co-founder, Myy Invest",
    image: Myyinvest,
    profile: Adebisi,
  },
  {
    logo: "Scorefam",
    story:
      "I chose Brass for my business because it is trusted and reliable. I like that transactions are free. A friend told me of Brass and the idea of an online bank managing my company funds is okay with me. So far, I've liked using Brass",
    name: "Simisoluwa Adeyemo",
    title: "Co-founder, Scorefam",
    image: Scorefam,
    profile: Simi,
  },
  {
    logo: "Lafya Health-share",
    story:
      "It took us 15 minutes to open a Brass account from the comfort of our homes. I love the fact that authorising funds is not limited to one person only. I can bank with my team despite us being in different locations",
    name: "Damilola Soyombo",
    title: "Co-founder, Lafya Health-share",
    image: Lafia,
    profile: Damilola,
  },
  {
    logo: "Beaumont foods",
    story:
      "I love the Brass experience. The UI is smooth and intuitive. Where there were issues, I mentioned them in passing and they were fixed. My account manager is also so helpful and responsive",
    name: "Bolanle Afuye",
    title: "Founder, Beaumont foods",
    image: Beaumont,
    profile: Bolanle,
  },
  {
    logo: "Paysimple Technologies",
    story:
      "Brass was very timely for us and we have totally enjoyed the relationship. Every startup needs Brass in the corner. I like the human support - we can always reach out to someone to resolve our issues.",
    name: "Oliver Johnson",
    title: "Founder, Paysimple Technologies",
    image: Paysim,
    profile: Oliver,
  },
  {
    logo: "",
    story:
      "We chose Brass simply due to the ease of onboarding and disbursing payments on the fly. Our experience with Brass Banking has been great so far",
    name: "Seyi Fakoya",
    title: "Founder, SAJA",
    image: Saja,
    profile: Seyi,
  },
  {
    logo: "",
    story:
      "Brass gives me and my partner equal control over our account and I have also said goodbye to transaction fees",
    name: "Omolara Sanni",
    title: "CEO, Venia Beauty",
    image: Venia,
    profile: Omolara,
  },
  {
    logo: "",
    story:
      "Brass makes it ridiculously easy for me to reconcile payments and invoices, without having to keep too many spreadsheets or double check with too many people. It's one platform to rule them all.",
    name: "Gbenga Osowe",
    title: "Supply lead, Eden",
    image: EdenLife,
    profile: Gbenga,
  },
  {
    logo: "",
    story:
      "Brass is accessible, with no rigorous but yet detailed account opening processes. When you say Brass, You hear a company that listens to their clients and we are happy to do business with them",
    name: "Oluwatobi Adewale-Temowo",
    title: "CEO, Tellerpay",
    image: Tellerpay,
    profile: Oluwatobi,
  },
  {
    logo: "",
    story:
      "Brass has helped me track inflows, expenses, schedule payments and disburse to consultants and service providers seamlessly. How keen the customer service was to help me resolve an issue I had stood out for me.",
    name: "Mojolaoluwa Olaifa",
    title: "CEO, Zyden Consult",
    image: Zyden,
    profile: Mojola,
  },
  {
    logo: "",
    story:
      "Our lives have been significantly easier since we started using the Brass platform. We initially struggled with finding the right bank that would safely keep our funds, and not stress or burden our lives.",
    name: "Aderinsola Akintilo",
    title: "CEO, SiriusLabs",
    image: Sirius,
    profile: Aderinsola,
  },
  {
    logo: "",
    story:
      "With Brass, I know I am being listened to, Brass has also provided me with tools that help me manage my accounts, allowing me to focus on that which is important to the survival of my business; making money",
    name: "Mary Job",
    title: "Founder, How do you Tech",
    image: Howdo,
    profile: Mary,
  },
  {
    logo: "",
    story:
      "The dashboard tells me where money is coming from, and where it is going even before seeing my statement. Also, I have an account manager and the team-banking feature does it for me. I don't have to approve all payments.",
    name: "Prince Agama",
    title: "CEO, Gravity media",
    image: Gravity,
    profile: Prince,
  },
  {
    logo: "",
    story:
      "Brass has made the onboarding process of getting banked very seamless. I literally opened my business account on the same day which has never really happened because I dread going to the bank, filling multiple forms.",
    name: "Gerald Okonkwo",
    title: "CEO, Parkit",
    image: Parkit,
    profile: Gerald,
  },
];

import React from "react";

//Customers
import RMDSImg from "../../assets/media/images/payroll/RMDS-retail.png";
import PithImg from "../../assets/media/images/payroll/pith.png";
import OduImg from "../../assets/media/images/payroll/odu.png";
import PartCloudImg from "../../assets/media/images/payroll/partcloud.png";

// Qualities
import BuiltToScale from "../../assets/media/gifs/payroll/built-to-scale.gif";
import EasyAndFast from "../../assets/media/gifs/payroll/easy-and-fast-to-set-up.gif";
import LoadedBenefit from "../../assets/media/gifs/payroll/loaded-with-benefits.gif";

// Payroll for teams
import OnboardingImg from "../../assets/media/images/payroll/payroll-for-teams-onboarding.png";
import EasySalaryImg from "../../assets/media/images/payroll/payroll-for-teams-easy-salary.png";
import AutoSalaryImg from "../../assets/media/images/payroll/payroll-for-teams-auto-salary.png";
import GenerateReportImg from "../../assets/media/images/payroll/payroll-for-teams-generate-report.png";

// Services
import PrivateToolsImg from "../../assets/media/images/payroll/private-tools.png";
import EmployeesImg from "../../assets/media/images/payroll/employees.png";
import BrassMoneyImg from "../../assets/media/images/payroll/brassmoney.png";

import {
  Pricing,
  PricingFeatureType,
  PricingType,
} from "components/payroll/Pricing/types/generics";
import {
  BankingIcon,
  CapitalIcon,
  CashFlowIcon,
  CommerceIcon,
  ExpenseManagementIcon,
  InvoiceIcon,
} from "assets/media/svgs";
import { BRASS_MONEY_URL } from "data/config";

export const HERO_VIDEO =
  "https://s3.eu-west-1.amazonaws.com/cms.trybrass.com/payroll_hero_49ce811f8f.mp4";

export const QUALITIES = [
  {
    title: "Easy and fast set up",
    desc: "Go from team onboarding to running your payroll every month in minutes.",
    img: EasyAndFast,
  },
  {
    title: "Built to scale",
    desc: "Suitable for small to big businesses with benefits and payroll operations.",
    img: BuiltToScale,
  },
  {
    title: "Loaded with benefits",
    desc: "Payroll service with tools, and benefits to help you make better financial decisions.",
    img: LoadedBenefit,
  },
];

export const PAYROLL_FOR_TEAMS = [
  {
    title: "Onboard your teams in minutes",
    text: "Generate a link that only works with official domains, or simply invite people to get started.",
    img: OnboardingImg,
  },
  {
    title: "Easy salary configuration",
    text: "Set up your payments, add allowances and deductions, taxes and make other preferences to your account.",
    img: EasySalaryImg,
  },
  {
    title: "Automatic salary payments",
    text: "Customise your payroll to run manually or automatically on payroll due dates.",
    img: AutoSalaryImg,
  },
  {
    title: "Generate reports anytime",
    text: "Download payroll reports you need directly from your dashboard anytime you need it.",
    img: GenerateReportImg,
  },
];

export const OPERATIONS = [
  {
    title: "Payroll operations and insights like no other",
    desc: "Run payroll better. Get relevant insights and detailed reports from your payroll dashboard and make better decisions on your business’ largest expense.",
    isAvailablie: true,
  },
  {
    title: "Extend your runway with Payroll financing",
    desc: "Get up to 15% payroll financing to support your payroll expense when you need it without applying. Simply withdraw the extra cash you need to meet your payroll needs as you run.",
    isAvailablie: false,
  },
];

export const SERVICES = [
  {
    text: (
      <a href={BRASS_MONEY_URL} target="_blank" rel="noopener noreferrerr">
        <p>
          Integrates directly with the <span>Brassmoney</span>, built for
          professionals and their households.
        </p>
      </a>
    ),
    img: BrassMoneyImg,
  },
  {
    text: (
      <p>
        Private tools for your team to take charge of their payroll and banking
        transactions.
      </p>
    ),
    img: PrivateToolsImg,
  },
  {
    text: (
      <p>
        Employees can have on-demand interest-free access to salaries with
        Instant Pay.
      </p>
    ),
    img: EmployeesImg,
  },
];

export const CUSTOMERS = [
  {
    img: RMDSImg,
    role: "RMDS Retail",
  },
  {
    img: PartCloudImg,
    role: "Partcloud Co",
  },
  {
    img: OduImg,
    role: "Odu Fashion",
  },
  {
    img: PithImg,
    role: "Pith Africa",
  },
];

export const TESTIMONIALS = [
  {
    testimony: `The process of setting up payroll was a breeze. After a single, streamlined setup, it has been operating flawlessly on its own - we've had no reason to give it a second look. It's the definition of set-and-forget efficiency.`,
    name: "Ted Oladele",
    role: "Co-founder",
    company: "Mira",
  },
  {
    testimony: `Brass Payroll has been incredibly helpful from the outset, making payroll processing fast and easy for us.`,
    name: "Akim John",
    role: "Brand and Product",
    company: "Kwakol",
  },
  {
    testimony: `Would call it a God sent feature. The payroll feature allow us to automate, track and make payment with ease, the best part of the experience so far is the dedicated customer support that made onboarding seamless.`,
    name: "Josemaria Agulanna",
    role: "Senior Ops Lead",
    company: "Keble",
  },
];

export const PRICING_FEATURES: PricingFeatureType[] = [
  {
    text: "Pay salaries",
    availability: "all",
  },
  {
    text: "Set up multiple locations",
    availability: "all",
  },
  {
    text: "Set up multiple teams",
    availability: "all",
  },
  {
    text: "Set up taxes and pensions",
    availability: "single",
  },
  {
    text: "Generate and download reports",
    availability: "all",
  },
  {
    text: "Automatic tax remittance",
    availability: "single",
  },
  {
    text: "No transaction fee on salary payments",
    availability: "multiple",
  },
];

export const PRICING: Pricing = {
  [PricingType.Simple]: [
    {
      cost: "Free",
      range: "₦500K",
      company: "For company payroll under",
      feature: PRICING_FEATURES.filter((item) => item.availability === "all"),
    },
    {
      cost: "₦7,500",
      range: "₦500K - ₦999,999",
      company: "Company payroll from",
      feature: PRICING_FEATURES.filter(
        (item) => item.availability !== "single"
      ),
    },
    {
      cost: "₦15,000",
      range: "₦1m",
      company: "Company payroll from",
      feature: PRICING_FEATURES.filter(
        (item) => item.availability !== "single"
      ),
    },
  ],
  [PricingType.Standard]: [
    {
      cost: "₦25,000",
      additionalFee: "₦500 per employee",
      feature: PRICING_FEATURES,
    },
  ],
};

export const PLATFORMS = [
  { icon: <BankingIcon />, text: "Banking" },
  { icon: <CapitalIcon />, text: "Capital" },
  { icon: <CommerceIcon />, text: "Commerce Solutions" },
  { icon: <InvoiceIcon />, text: "Invoices" },
  { icon: <ExpenseManagementIcon />, text: "Expense Management" },
  { icon: <CashFlowIcon />, text: "Cashflow services" },
];

export const frontmatter = {
  name: "payroll",
  faqContent: [
    {
      question: "Can I calculate pension contributions on the payroll account?",
      answer:
        "Yes you can. You can also add the pension providers your employee use.",
    },
    {
      question: "Can I pause my payroll at any time?",
      answer: "Yes you can.",
    },
    {
      question: "How many times can I run payroll in a month?",
      answer: "You currently can only run payroll once a month. ",
    },
    {
      question:
        "Is there a limit to the number of employees that can be onboarded on Brass payroll?",
      answer:
        "There's no limit to the number of employees you can add to your payroll.",
    },
  ],
  relatedPosts: [
    {
      image: "../../assets/media/images/cashflow.png",
      title:
        "Cash Flow Management for Small Businesses: How to Keep Your Business Afloat + Template",
      link: "https://www.trybrass.com/guides/growing-a-business/cashflow",
      excerpt:
        "In this article, we'll go in-depth into the meaning of cash flow, provide tips and advice on how to effectively manage cash flow.",
    },
    {
      image: "../../assets/media/images/business-credit.jpg",
      title: "What you need to know about Business Credit",
      link: "https://www.trybrass.com/guides/running-a-business/business-credit",
      excerpt: "Learn 4 ways to build your business credit in Nigeria.",
    },
    {
      image: "../../assets/media/images/tax.png",
      title: "Everything you need to know about small business tax in Nigeria",
      link: "http://www.trybrass.com/guides/running-a-business/guide-to-business-tax",
      excerpt:
        "Trying to file for taxes and not sure where to start from? This guide provides a breakdown to what taxes are.",
    },
  ],
};

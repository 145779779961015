import MinimalistNgHeadshot from "assets/media/images/customers/minimalistng/headshot1.png";
import HustlerCapitalHeadshot from "assets/media/images/customers/hustler-capital/headshot1.png";
import OdusFashionHeadshot from "assets/media/images/customers/odus-fashion/headshot1.png";
import PartCloudHeadshot from "assets/media/images/customers/partcloud/headshot1.png";

export const LEARN_MORE_OPTIONS = [
  {
    title: "Explore opportunities with us",
    description:
      "Join a team of diverse and talented people to help build a more successful and permission-less entrepreneurship.",
    link: "",
  },
  {
    title: "Learn from some of our customers",
    description:
      "Learn from stories and the impacts some of our customers are making in their community with their work.",
    link: "",
  },
];

export const SME_RESOURCES = [
  {
    label: "Identifying SMEs and growth in Sub-Sahara Africa",
    link: "https://www.norfund.no/archive/Bilder/Publications/SME%20and%20growth%20MENON%20.pdf",
  },
  {
    label: "Making Finance work in Africa",
    link: "https://openknowledge.worldbank.org/handle/10986/6626",
  },
  {
    label: "Promoting SME competitiveness in Africa",
    link: "https://www.intracen.org/uploadedFiles/intracenorg/Content/Publications/Africa_SME%20web.pdf",
  },
  {
    label: "Financing the growth of SMEs in Africa",
    link: "https://www.sciencedirect.com/science/article/pii/S1879933717300362",
  },
  {
    label: "Why the Future is African - and why SMEs should lead the way",
    link: "https://nextbillion.net/future-african-smes/",
  },
  {
    label:
      "Report on Support to SMEs in developing countries through financial intermediaries.",
    link: "https://www.eib.org/attachments/dalberg_sme-briefing-paper.pdf",
  },
];

export const CUSTOMERS_GRID_DATA = [
  {
    caption: "Minimalist NG, a Brass customer",
    img: MinimalistNgHeadshot,
  },
  {
    caption: "Hustler Capital, a Brass customer",
    img: HustlerCapitalHeadshot,
  },
  {
    caption: "Odu Fashion, a Brass customer",
    img: OdusFashionHeadshot,
  },
  {
    caption: "Partcloud Co, a Brass customer",
    img: PartCloudHeadshot,
  },
];

export const frontmatter = {
  name: "why-brass",
  customerData: [
    {
      caption: "Minimalist NG, a Brass customer",
      img: "../../assets/media/images/customers/minimalistng/headshot1.png",
    },
    {
      caption: "Hustler Capital, a Brass customer",
      img: "../../assets/media/images/customers/hustler-capital/headshot1.png",
    },
    {
      caption: "Odu Fashion, a Brass customer",
      img: "../../assets/media/images/customers/odus-fashion/headshot1.png",
    },
    {
      caption: "Partcloud Co, a Brass customer",
      img: "../../assets/media/images/customers/partcloud/headshot1.png",
    },
  ],
};

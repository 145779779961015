import { HONESTY_URL } from "data/config";

import Growth from "assets/media/images/careers/growth.png";
import Honesty from "assets/media/images/careers/honesty.png";
import Quality from "assets/media/images/careers/quality.png";
import Respect from "assets/media/images/careers/respect.png";
import Customer from "assets/media/images/careers/customer.png";
import Ownership from "assets/media/images/careers/ownership.jpg";
import Communicate from "assets/media/images/careers/communicate.png";
import Transparency from "assets/media/images/careers/transparency.png";

import PTO from "assets/media/images/careers/pto.png";
import Parental from "assets/media/images/careers/parental.png";
import Learning from "assets/media/images/careers/learning.png";
import Relocation from "assets/media/images/careers/relocation.png";
import Healthcare from "assets/media/images/careers/healthcare.png";
import Compensation from "assets/media/images/careers/compensation.png";

export const VALUES = [
  {
    image: Quality,
    title: "Obsession with high quality and excellence",
    description: `We build financial products for local businesses and the people behind these businesses. It is very important we do our work correctly and be there for our customers. 
      <br /><br />
      We therefore, seek to build insanely great financial products and deliver the best customer services. We think very deeply about our work. We are obsessed with a very high quality in all of our work and we hope you see that in our products and services. More than that, we require that from everyone we work with it.`,
  },
  {
    image: Honesty,
    link: HONESTY_URL,
    title: "Intellectual honesty",
    linkText: "Learn more about Intellectual honesty",
    description: `
      We expect people to be human, to not know-it-all and that's where we 
      hold everyone to intellectual honesty. Ability to ask colleagues for help when you need it, remove blockers when you see it without shame is very critical to us.
      <br /><br />
      We are drawn to people that ask questions and admit they don't know. We seek to find the truth, not to win arguments in our interactions so we can simply move forward fast.
      `,
  },
  {
    image: Ownership,
    title: "Ownership",
    description: `At Brass, everyone is almost CEO at their own responsibility. We give resources and expect ownership so you can do your best work and own your success. We are very result-driven and communicate clearly our expectations for every role you assume.`,
  },
  {
    image: Respect,
    title: "Respect",
    description: `Brass has an unspoken no-asshole rule. It is a fairly flat company and we treat everyone, regardless of their role with respect and dignity. This must translate from our interactions with everyone, and how we manage our place in their time and space. Everyone must feel safe and know they will be heard if they talk.`,
  },
  {
    image: Communicate,
    title: "Communicate clearly",
    description: `We in fact, over-communicate. When needed, please communicate and be as clear as much as possible and confirm you are understood. We exchange a lot of ideas everyday across various teams, it is very important these ideas are preserved in their original form when they are being executed. Clarity is the only way we can maintain integrity in shared information.`,
  },
  {
    image: Growth,
    title: "Growth mindset",
    description: `We are a knowledge company. We read, research, learn from one another, ask questions, talk to our customers. We seek to get the most information we need to do our work.
    <br /><br />
    As a people we are building a type of company in a market where it has never been built before. To succeed, we must actively learn and seek to grow individually, and in our teams. Personal growth is very critical to our success as a company.`,
  },
  {
    image: Transparency,
    title: "Transparency",
    link: HONESTY_URL,
    linkText: "See Intellectual honesty.",
    description: `We communicate openly. Very often, we share our updates internally and do demos of our work to get everyone updated with the state of the business. 
`,
  },
  {
    image: Customer,
    title: "Customer first",
    description: `To deliver incredible, remarkable experiences for our customers, we must put the customer at the center of our work. For us, customer service is not a customer success function. It is everyone's. 
    <br /><br />
    We think about the customer every time. When we communicate, we must be honest. When we design, it must be clear, clutter-free and functional. Same for engineering and other teams.`,
  },
];

export const BENEFITS = [
  {
    image: Compensation,
    title: "Competitive Compensation",
    description: `Industry and market competitive compensation and in addition, our equity plans allow you to participate in our success and growth as a company.`,
  },
  {
    image: Relocation,
    title: "Relocation and mobility grant",
    description: `Up to N6m relocation and mobility(car) grant so you can live more comfortably as you do your best work.`,
  },
  {
    image: Healthcare,
    title: "Healthcare Package",
    description: `Full medical insurance plan so you have the best medical care and support when you need it.`,
  },
  {
    image: Learning,
    title: "Learning and Development",
    description: `Access library of books, great team of smart & kind people and resources you need to grow in your role at any stage.`,
  },
  {
    image: Parental,
    title: "Paid parental leave",
    description: `Get the time you need to take care of your child as a father or mother when the time comes.`,
  },
  {
    image: PTO,
    title: "Paid time-off",
    description: `When you need it, take a paid time-off so you can rest and recharge.`,
  },
];

export const frontmatter = {
  name: "careers",
  heroImages: [
    {
      img: "../../assets/media/images/careers/ladies.png",
    },
    {
      img: "../../assets/media/images/careers/nifries.png",
    },
    {
      img: "../../assets/media/images/careers/odu.png",
    },
    {
      img: "../../assets/media/images/careers/partcloud.png",
    },
  ],
  customerData: [
    {
      img: "../../assets/media/images/customers/hustler-capital/headshot1.png",
    },
    {
      img: "../../assets/media/images/customers/minimalistng/headshot1.png",
    },
    {
      img: "../../assets/media/images/customers/odus-fashion/headshot1.png",
    },
  ],

  spotlight: [
    {
      sex: "female",
      image: "../../assets/media/images/careers/Chiamaka.jpg",
      name: "Chiamaka",
      team: "Operations",
      link: "/blog/inside-brass/chiamaka-okany",
    },
    {
      sex: "male",
      image: "../../assets/media/images/careers/damilola.jpg",
      name: "Oluwadamilola",
      team: "Engineering",
      link: "/blog/inside-brass/oluwadamilola-babalola/",
    },
    {
      sex: "female",
      image: "../../assets/media/images/careers/temi.jpg",
      name: "Temiloluwa",
      team: "Marketing and communications",
      link: "/blog/inside-brass/temi-oyetayo/",
    },
    {
      sex: "female",
      image: "../../assets/media/images/careers/tomisin.jpg",
      name: "Tomisin",
      team: "Marketing and communications",
      link: "/blog/inside-brass/Tomisin-Dabiri",
    },
    {
      sex: "female",
      image: "../../assets/media/images/careers/princess.jpg",
      name: "Princess",
      team: "Product and Design",
      link: "/blog/inside-brass/princess-akari/",
    },
    {
      sex: "female",
      image: "../../assets/media/images/careers/Onyeka.jpg",
      name: "Onyeka",
      team: "Product and Design",
      link: "/blog/inside-brass/OnyekaElumelu",
    },
  ],
};

import React from "react";
import { Link } from "gatsby";

export default {
  title: "Terms of Service",
  lastUpdated: "July 25, 2020",
  sections: [
    {
      label: "1. About",
      markup: (
        <>
          You are welcome to <Link to="/">www.trybrass.com</Link> (the{" "}
          <b>“website”</b>
          or <b>“site”</b>). Henceforth, <b>“site”</b> shall be interpreted to
          include other Brass’ platforms (including mobile applications), which
          Brass may make available to you from time to time.
          <br />
          <br />
          This site is operated and maintained by <b>
            Copper Brass Limited
          </b>{" "}
          (“Brass”, “we”, “our”, “us”). We are registered in Nigeria under the
          Registration Company Number RC 7483800 and have our registered address
          at 50 Raymond Njoku, Ikoyi, Lagos, Nigeria. We are a Limited Liability
          Company regulated by the Corporate Affairs Commission. To contact us,
          please email <a href="mailto:help@trybrass.com">help@trybrass.com</a>{" "}
          or call our customer service line on 02017002000.
          <br />
          <br />
          Brass makes every effort to ensure all information on this site is as
          accurate as possible.
        </>
      ),
    },
    {
      label: "2. By using our site, you accept these terms",
      markup: (
        <>
          By using our site, you irrevocably confirm that you accept our Terms
          and Conditions of use (these “terms”), Acceptable Use Policy, as well
          as our Privacy Policy and agree to comply with them.
          <br />
          <br />
          <h3>Prohibited uses:</h3>
          <br />
          You may use the Site only for lawful purposes. You may not use the
          Site:
          <ul>
            <li>
              In any way that breaches any applicable local, national or
              international law or regulation.
            </li>
            <li>
              In any way that is unlawful or fraudulent or has any unlawful or
              fraudulent purpose or effect.
            </li>
            <li>
              For the purpose of harming or attempting to harm any person and in
              any way.
            </li>
            <li>To bully, insult, intimidate or humiliate any person.</li>
            <li>
              To send, knowingly receive, upload, download, use or re-use any
              material which does not comply with our content standards.
            </li>
            <li>
              To transmit, or procure the sending of, any unsolicited or
              unauthorised advertising or promotional material or any other form
              of similar solicitation (spam).
            </li>
            <li>
              To knowingly transmit any data, send or upload any material that
              contains viruses, trojan horses, worms, time-bombs, keystroke
              loggers, spyware, adware or any other programs or similar computer
              code designed to adversely affect the operation of any computer
              software or hardware.
            </li>
            <li>
              To engage in the trade of cryptocurrency, virtual assets and any
              other such transactions not permitted by relevant financial
              regulatory bodies.
            </li>
          </ul>
          <h3> You also agree:</h3>
          <ul>
            <li>
              Not to reproduce, duplicate, copy or re-sell any part of our Site
              as this is in contravention of the provisions of our terms of use.
            </li>
            <li>
              Not to access without authority, interfere with, damage or
              disrupt:
              <li>any part of our Site;</li>
              <li>any equipment or network on which our Site is stored; or</li>
              <li>any software used in the provision of our Site.</li>
            </li>
          </ul>
        </>
      ),
    },
    {
      label: "3. Access to the site",
      markup: (
        <>
          You are responsible for providing all hardware, software, telephone or
          other communications equipment and/or service to connect to the
          Internet and access our site, and are responsible for all Internet
          access charges, telephone charges or other fees or charges incurred in
          connecting to the Internet and accessing our site
        </>
      ),
    },
    {
      label: "4. Interactive services",
      markup: (
        <>
          Where we do provide any interactive service, we will provide clear
          information to you about the kind of service offered, if it is
          moderated and what form of moderation is used (including whether it is
          human or technical).
          <br />
          <br />
          We will do our best to assess any possible risks for users from third
          parties when they use any interactive service provided on our site,
          and we will decide in each case whether it is appropriate to use
          moderation of the relevant service (including what kind of moderation
          to use) in the light of those risks. However, we are under no
          obligation to oversee, monitor or moderate any interactive service we
          provide on our site, and we expressly exclude our liability for any
          loss or damage arising from the use of any interactive service by you
          in contravention of our content standards, whether the service is
          moderated or not.
          <br />
          <br />
          Where we moderate on an interactive service, we will normally provide
          you with a means of contacting the moderator, should a concern or
          difficulty arise.
        </>
      ),
    },
    {
      label: "5. We may make changes to these terms",
      markup: (
        <>
          We may update, revise, amend and/or change our site from time to time
          (to reflect our users’ needs and any of our business priorities. Every
          time you wish to use our site, please check these terms to ensure you
          understand the terms that apply at that time.
        </>
      ),
    },
    {
      label: "6. We may suspend or withdraw our site",
      markup: (
        <>
          our site is made available free of charge. We do not guarantee that
          our site, or any content on it, will always be available or be
          uninterrupted. We may suspend or withdraw or restrict the availability
          of all or any part of our site for business and operational reasons.
          We will try to give you reasonable notice of any suspension or
          withdrawal. you are also responsible for ensuring that all persons who
          access our site through your internet connection are aware of these
          terms of use and other applicable terms and conditions, and that they
          comply with them.
        </>
      ),
    },
    {
      label: "7. How you may use material on our site",
      markup: (
        <>
          We are the owner of all the Intellectual Property Rights (IP or IPR)
          on our site and the materials published on it. The IP works are
          protected by prevalent intellectual property laws in Nigeria and
          around the world, especially the treaties by the World Intellectual
          Property Organization (WIPO). All such rights are reserved.
          <br />
          <br />
          Where our site contains links to other sites and resources provided by
          third parties, these links are provided for your information only.
          Such links should not be interpreted as approval by us of those linked
          sites or information you may obtain from them. We have no control over
          the contents of those sites or resources.
          <br />
          <br />
          You may print off one copy, and may download extracts, of any page(s)
          from our site for your personal use and you may draw the attention of
          others within your organisation to content posted on our site.
          <br />
          <br />
          You must not modify the paper or digital copies of any materials you
          have printed off or downloaded in any way, and you must not use any
          illustrations, photographs, video or audio sequences or any graphics
          separately from any accompanying text.
          <br />
          <br />
          Our status (and that of any identified contributors) as the authors of
          content on our site must always be acknowledged.
          <br />
          <br />
          You must not use any part of the content on our site for commercial
          purposes without obtaining a licence to do so from us.
          <br />
          <br />
          If you print off, copy or download any part of our site in breach of
          these terms of use, your right to use our site will cease immediately
          and you must, at our option, return or destroy any copies of the
          materials you have made.
        </>
      ),
    },
    {
      label: "8. You must keep your details safe",
      markup: (
        <>
          If you choose, or you are provided with, a user identification code,
          password or any other piece of information as part of our security
          procedures, you must treat such information as confidential. you must
          not disclose this information to any third party.
          <br />
          <br />
          We have the right to disable any user identification code or password,
          whether chosen by you or allocated by us, at any time, if in our
          reasonable opinion you have failed to comply with any of the
          provisions of these terms.
          <br />
          <br />
          If you know or suspect that anyone other than you, knows your user
          identification code or password, you must promptly notify us via any
          of our contact medium.
          <br />
          <br />
          You should notify us of any change of information in your user
          application such as change of address and contact information via any
          of our contact medium.
        </>
      ),
    },
    {
      label: "9. Do not rely on information on this site",
      markup: (
        <>
          The content on our site is provided for general information only. It
          is not intended to amount to advice on which you should rely. you must
          obtain professional or specialist advice before taking, or refraining
          from, any action on the basis of the content on our site.
          <br />
          <br />
          Although we make reasonable efforts to update the information on our
          site, we make no representations, warranties or guarantees, whether
          express or implied, that the content on our site is accurate, complete
          or up to date.
        </>
      ),
    },
    {
      label: "10. We are not responsible for sites we link to",
      markup: (
        <>
          Where our site contains links to other sites and resources provided by
          third parties, these links are provided for your information only.
          Such links should not be interpreted as approval by us of those linked
          websites or information you may obtain from them. We have no control
          over the contents of those sites or resources.
        </>
      ),
    },
    {
      label:
        "11. We are not responsible for viruses and you must not introduce them",
      markup: (
        <>
          We do not guarantee that our site will be secure or free from bugs or
          viruses.
          <br />
          <br />
          You are responsible for configuring your information technology,
          computer programmes and platform to access our site. You should use
          your own virus protection software.
          <br />
          <br />
          You must not attempt to gain unauthorised access to our site, the
          server on which our site is stored or any server, computer or database
          connected to our site. You must not attack our site via a
          denial-of-service attack or a distributed denial-of-service attack or
          by any means gain any unauthorised access to segments of our site,
          which are not ordinarily accessible to the public. By breaching this
          provision, you would commit a criminal offence under the relevant
          Nigeria criminal laws, including but not limited to the Cyber Crimes
          (Prohibition, Prevention, etc.) Act 2015**.** We will report any such
          conduct to the relevant law enforcement authorities, and we will
          cooperate with those authorities by disclosing your identity to them.
          In the event of such a breach your right to use our site will cease
          immediately.
        </>
      ),
    },
    {
      label: "12. Rules about linking to our site",
      markup: (
        <>
          You may link to our home page, provided you do so in a way that is
          fair and legal and does not damage our reputation or take unauthorised
          advantage of us. You must not establish a link in such a way as to
          suggest any form of association, approval or endorsement on our part
          where none exists. You must not establish a link to our site in any
          website that is not owned by you.
          <br />
          <br />
          our site must not be framed on any other site, nor may you create a
          link to any part of our site other than the home page.
          <br />
          <br />
          We reserve the right to withdraw linking permission without notice.
          The website in which you are linking must comply in all respects with
          the content standards set out in the terms.
          <br />
          <br />
          If you wish to link to or make any use of content on our site other
          than that set out above, please contact us.
        </>
      ),
    },
    {
      label: "13. The use of your personal data",
      markup: (
        <>
          By opening an account with us, you choose to provide us with your
          Registration Information, and you agree to update it from time to time
          so that it remains accurate.
          <br />
          <br />
          You hereby irrevocably authorize us to use your Registration
          Information and Account Activities at our sole discretion.
          <br />
          <br />
          You agree that we provide our services to you through some of our
          business partners, and that your account with us is currently
          domiciled with Bancorp MFBank Limited (the “partner bank”). You hereby
          authorize us to migrate your account with us to any other platform or
          bank and close your account with us at the Domicile Bank at any time
          that we may deem fit. Provided that we shall notify you prior to such
          migration and close-down. Further provided that you may elect that we
          do not migrate such account but open a new account with the newly
          migrated platform, the opening of which is at our sole discretion
          Where you do not elect otherwise within 3 (three) days from the date
          of the notice, you are deemed to have expressly authorised that we
          migrate your account from the Domicile Bank and close the account
          maintained with the Domicile Bank.
        </>
      ),
    },
    {
      label: "14. Termination of agreement",
      markup: (
        <>You may close your account with Brass at any time by notifying us. </>
      ),
    },
    {
      label: "15. Breach of these terms and conditions",
      markup: (
        <>
          When we consider that a breach of these terms has occurred, we may
          take such action as we deem appropriate.
          <br />
          <br />
          Failure to comply with this terms and conditions constitute a material
          breach, upon which you are no longer permitted to use our site, and
          may result in our taking all or any of the following actions:
          <ul>
            <li>
              Immediate, temporary or permanent withdrawal of your right to use
              our site.
            </li>
            <li>Issuance of a warning to you.</li>
            <li>
              Legal proceedings against you for reimbursement of all costs on an
              indemnity basis (including, but not limited to, reasonable
              administrative and legal costs) resulting from the breach.
            </li>
            <li>Further legal action against you.</li>
            <li>
              Disclosure of such information to law enforcement authorities as
              we reasonably feel is necessary or as required by law.
            </li>
          </ul>
          We exclude our liability for all action we may take in response to
          breaches of these terms. The actions we may take are not limited to
          those described above, and we may take any other action we reasonably
          deem appropriate.
        </>
      ),
    },
    {
      label: "16. Our rights",
      markup: (
        <>
          We reserve the right to use your personal information or any other
          information that you provided to us, to open a bank account with
          either the Domicile Bank or any other Bank other than the Domicile
          Bank.
          <br />
          <br />
          We have the right to take any necessary legal action based on fraud,
          abuse, or suspicious transaction activity in connection with any of
          your activities relating to Brass, and you agree to cooperate with any
          such action or investigation. If you are engaged in such activity, you
          may be liable to monetary losses, including litigation and/or
          arbitration costs and damages. This section shall survive the
          termination of your agreement with Brass.
        </>
      ),
    },
    {
      label: "17. How can we modify these terms",
      markup: (
        <>
          We may modify these terms in any manner, at any time, at our sole
          discretion. Be sure to check our site from time to time to ensure that
          you are aware of any changes to these terms. Modifications to these
          terms are effective immediately when posted. Except as set forth
          herein, modifications apply only to transactions and disputes that
          arise after the effective date. Your continued use of Brass after
          changes have been made to these terms constitutes your agreement to be
          irrevocably bound by the modified terms. If you do not agree to be
          bound by the modified terms, your only recourse is to terminate the
          agreement.
        </>
      ),
    },
    {
      label: "18. Disclaimers",
      markup: (
        <>
          We hereby irrevocably exclude and make no conditions, representations
          or warranties, either express or implied, including, but not limited
          to: any implied warranty of quality, condition, merchant-ability,
          fitness for intended use or a particular purpose, or those arising by
          statute or otherwise in law or from a course of dealing or usage of
          trade regarding Brass or the services, materials, or goods advertised,
          promoted, sold or otherwise made available through a merchant. You
          agree that your purchases of goods or services from a person other
          than Brass, are between you and the person, and are made at your own
          sole risk, which are subject to any additional terms, conditions and
          restrictions.
          <br />
          <br />
          We exclude all implied conditions, warranties, representations or
          other terms that may apply to our site or any content on it. We will
          not be liable to you for any loss or damage, whether in contract, tort
          (including negligence), breach of statutory duty, or otherwise, even
          if foreseeable, arising under or in connection with: use of, or
          inability to use, our site; or use of or reliance on any content
          displayed on our site, In particular, we will not be liable for (loss
          of profits, sales, business, or revenue; business interruption;
          business interruption; loss of anticipated savings; loss of business
          opportunity, goodwill or reputation; or any direct, indirect or
          consequential loss or damage.
          <br />
          <br />
          We are also not liable for any defective digital content that we have
          supplied, damages a device or digital content belonging to you whether
          caused by our failure to use reasonable care and skill, and/or for
          damage that you could have avoided by following our advice to apply an
          update offered to you free of charge or for damage that was caused by
          you failing to correctly follow installation instructions or to have
          in place the minimum system requirements advised by us.
        </>
      ),
    },
    {
      label: "19. Limitation of liability",
      markup: (
        <>
          Under no circumstances shall we be liable to you for any direct,
          indirect, incidental, consequential, special, exemplary or punitive
          damages, whether any claim is based on warranty, contract, tort
          (including, without limitation, negligence) or otherwise (even if any
          of them have been advised of the possibility of such damages). This
          limitation of liability shall apply also to damage(s) arising,
          including, without limitation, whether such damages are incurred by
          third parties, and/or whether such damages arise from transactions
          between you and a Merchant and/or Partners, your inability to use, or
          misuse Brass platforms, or the interruption, suspension, or
          discontinuation of Brass.
        </>
      ),
    },
    {
      label: "20. Governing law",
      markup: (
        <>
          The laws of the Federal Republic of Nigeria govern these terms and the
          subject matter of any aspect of your relationship with us. These laws
          govern without regard to any conflicts of international laws or
          cross-border domestic laws/statutes that would otherwise apply the
          substantive law of another jurisdiction.
        </>
      ),
    },
    {
      label: "21. Dispute resolution",
      markup: (
        <>
          This section only applies to Brass and the customer. Most customers’
          concerns can be resolved by calling our customers’ service centre call
          or email platform. In the event that the customers’ service centre is
          unable to resolve a complaint to your satisfaction, this section
          explains how claims can be resolved through the amicable means of
          informal mediation, arbitration (in accordance with the Arbitration
          and Conciliation Act, Cap A18, Laws of the Federation of Nigeria, 2010
          (“Act”), or litigation depending on the subject matter which the
          dispute resolution mechanism shall be solely approved by us upon
          notification by the customer. It includes an arbitration provision.
          For this dispute resolution section: (a) “you” includes you
          (customer), (any person acting on your behalf if you are a minor, your
          parents and legal guardians); (b) “we” and “us” includes Brass , our
          Merchants and any holding companies, subsidiaries, affiliates or
          related persons or entities, and, if there is an assignment, the
          assignee and its holding companies, subsidiaries, affiliates or
          related persons or entities; and (c) “claim” means any current or
          future claim, dispute or controversy relating to Brass including these
          terms, except for the validity, enforceability or scope of the
          arbitration provision. Claim includes but is not limited to: (1)
          initial claims, counterclaims, crossclaims and third-party claims; (2)
          claims based upon contract, tort, fraud, statute, regulation, common
          law and equity; and (3) claims by or against any third party using or
          providing any product, service, or benefit in connection with any
          Brass Service .
          <br />
          <br />
          You shall not sell, assign or transfer a claim. Sending a Claim Notice
          is a condition precedent before commencing a lawsuit, mediation or
          arbitration. You agree to send a written notice (a “Claim Notice”) to
          us, in order to provide an opportunity to resolve the claim informally
          or through mediation. Visit our site or call 02017002000 for a sample
          Claim Notice to be sent to you. The Claim Notice must describe the
          claim and state the specific relief demanded. Notice to you may be
          sent to your email or mailing address. Notice to us must include your
          name, address and Brass account number and be sent to 50 Raymond
          Njoku, Ikoyi, Lagos, Nigeria. If the claim proceeds to arbitration,
          the amount of any relief demanded in a Claim Notice (which maximum
          claim amount shall be solely determined by us) will not be disclosed
          to the arbitrator until after the arbitrator rules.
          <br />
          <br />
          You or we may elect to resolve any claim by individual arbitration.
          Claims will be decided by a neutral arbitrator. If arbitration is
          chosen by us, neither you nor we, will have the right to litigate that
          claim in courts or have a jury trial on that claim. Further, neither
          you nor we will have the right to participate in a representative
          capacity or as a Member of any class pertaining to any claim subject
          to arbitration. Arbitration procedures are generally simpler than the
          rules that apply in courts, and discovery is more limited. The
          arbitrator’s decisions are as enforceable as any court order in
          Nigeria and are subject to very limited review by a court which shall
          be disputable matters that are not subject to arbitration
          jurisdiction. The arbitrator’s decision will be final and binding.
          <br />
          <br />
          You will be responsible for paying the arbitration fees (including
          filing, administrative, hearing or other fees), we will be responsible
          for any additional arbitration fees. At your written request, we will
          consider in reasonable good faith making a temporary advance of our
          share of any arbitration fees, or paying for the fees of an expert
          appointed by the arbitrator for good cause.
          <br />
          <br />
          If the arbitrator rules in your favour for an amount greater than any
          settlement offer we made before arbitration, the arbitrator’s award
          shall only include any money to which you are entitled and any
          reasonable attorneys’ fees, costs and expert and other witness fees
          but shall not be more than ₦50,000.
        </>
      ),
    },
    {
      label: "22. Miscellaneous",
      markup: (
        <>
          This agreement comprises the entire agreement between Brass and you
          and supersedes any prior agreements with respect to the subject matter
          herein. Brass may revise this agreement or any other policy at any
          time and from time to time, and such revision shall be effective two
          (2) days upon posting notice of such revision prominently on our site.
          You agree to review this agreement periodically to be aware of such
          revisions. If any such revision is unacceptable to you, you must
          discontinue accessing our site.
          <br />
          <br />
          Your continued accessing and use of the our site following notice of
          any such revision shall conclusively be deemed acceptance of all such
          revisions.
          <br />
          <br />
          This agreement is personal to you and you may not assign your rights
          or obligations thereunder to anyone. All logos, brand names, products,
          trademarks and service marks appearing herein may be the trademarks or
          service marks of their respective owners. References to any trademark,
          service mark and links to or from the our site have been done strictly
          for clarification and identification and does not constitute
          endorsement by Brass of the products, services or information offered
          by the owner of such trademark, service mark or link or endorsement of
          Brass by such trademark, service mark or link owner.
        </>
      ),
    },
    {
      label: "23. Contact Us",
      markup: (
        <>
          You may contact us regarding the Service or these Terms at: Copper
          Brass, Inc., 50 Raymond Njoku, Ikoyi, Lagos, Nigeria or via email to
          <a href="mailto:help@trybrass.com"> help@trybrass.com</a>
        </>
      ),
    },
  ],
};

type PricingFeatureAvailability = "single" | "multiple" | "all";

export type PricingFeatureType = {
  text: string;
  availability: PricingFeatureAvailability;
};

export enum PricingType {
  Simple = "simple",
  Standard = "standard",
}

export interface PricingDataType {
  cost: string;
  range?: string;
  company?: string;
  additionalFee?: string;
  feature: PricingFeatureType[];
}

export interface Pricing {
  [x: string]: PricingDataType[];
}
